import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tax-invoice-category',
  templateUrl: './tax-invoice-category.component.html',
  styleUrls: ['./tax-invoice-category.component.scss']
})
export class TaxInvoiceCategoryComponent implements OnInit, OnDestroy {
  category: string;
  queryParams: any = {};
  paramsSubscription: Subscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.category = params.get('category');
      this.queryParams = params.keys.reduce((acc, key) => Object.assign(acc, {[key]: params.get(key)}), {});
    });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  paramsFor(category?: string) {
    if (category) {
      return Object.assign({}, this.queryParams, {category: category});
    } else {
      let params = Object.assign({}, this.queryParams);
      delete params.category;
      return params;
    }
  }
}