import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  company$: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(private firestore: AngularFirestore, private auth: AngularFireAuth) {
    this.currentCompany();
  }

  currentCompany() {
    this.auth.onIdTokenChanged((user) => {
      user?.getIdTokenResult(false).then((tokenResult) => {
        this.firestore.doc(`empresas/${tokenResult?.claims?.company}`)
          .get()
          .toPromise()
          .then((docSnap) => {
            if (docSnap.exists) {
              this.company$.next(Object.assign(docSnap.data(), {id: docSnap.id}));
            }
          });
      })
    });
  }
}
