import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private sys: SystemService, private afAuth: AngularFireAuth, private http: HttpClient) { }

  getReport(params = {}) {
    this.sys.openLoading('Gerando relatórios...');
    return from(this.afAuth.currentUser)
    .pipe(
      switchMap((user) => {
        if (user) {
          return from(user.getIdTokenResult(false));
        } else {
          return null;
        }
      }),
      switchMap((tokenResult) => {
        const headers = {"Authorization": `Bearer ${tokenResult.token}`}
        return this.http.get<any>(`${environment.baseApiUrl}/reports/${tokenResult.claims?.company}`,
          {
            headers: headers,
            params: params
          }
        )
      }),
      catchError((err) => {
        console.log(err);
        return null;
      })
    )
    .toPromise()
    .finally(() => this.sys.closeLoading())
  }
}
