<div class="modal-header">
  <a class="mr-3 back-btn" role="button" (click)="modalRef.dismiss()">
    <img src="../../../assets/icons/btnback.svg" alt="Voltar" height="28"
  /></a>

  <section class="d-flex justify-content-between w-100">
    <div class="pl-1">
      <h2>Nota Fiscal</h2>
      <div class="text-muted font-weight-bold txt-gray-300">#001</div>
      <small class="text-muted txt-gray-100 letter-spacing">Trimestral</small>
    </div>
    <div ngbDropdown placement="bottom-right" class="d-inline-block">
      <a role="button" id="table-show-dropdown" ngbDropdownToggle
        ><i class="pi pi-ellipsis-v txt-gray-300"></i
      ></a>
      <div ngbDropdownMenu aria-labelledby="table-show-dropdown">
        <button ngbDropdownItem>Menu 1</button>
        <button ngbDropdownItem>Menu 2</button>
      </div>
    </div>
  </section>
</div>
<div class="modal-body">
  <div class="pl-5">
    <section class="d-flex flex-column">
      <h3>Empresa ABC</h3>
      <small>Cálculo do CSLL</small>
      <small class="font-weight-bold"
        >(feito com base nas receitas das notas fiscais importadas e
        emitidas)</small
      >
      <img src="../../../assets/img/hotBorder.svg" class="mt-4" alt="" />
    </section>
    <section class="mt-3">
      <div class="row">
        <div class="col-3">
          <div class="d-flex flex-column">
            <small class="txt-gray-100 letter-spacing mb-1">PERÍODO</small>
            <span class="mb-3">1º Trimestre</span>
            <small class="txt-gray-100 letter-spacing mb-1"
              >DATA DE VENCIMENTO</small
            >
            <small class="txt-primary">20 de abril de 2021</small>
            <small class="">Código DARF 2372</small>
          </div>
        </div>
        <div class="col-9">
          <h4>Receitas</h4>
          <div class="table-revenue">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <small class="txt-gray-100 letter-spacing">
                      Janeiro/2021
                    </small>
                  </th>
                  <th>
                    <small class="txt-gray-100 letter-spacing">
                      Fevereiro/2021
                    </small>
                  </th>
                  <th>
                    <small class="txt-gray-100 letter-spacing">
                      Março/2021
                    </small>
                  </th>
                  <th>
                    <small class="txt-gray-100 letter-spacing">TOTAL</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="row-desc">Receitas de Serviços</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 30.000,00</td>
                </tr>
                <tr>
                  <td class="row-desc">Receitas de Comércio</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 30.000,00</td>
                </tr>
                <tr>
                  <td class="row-desc font-weight-bold">(-) Deduções</td>
                  <td>R$ 0,00</td>
                  <td>R$ 0,00</td>
                  <td>R$ 0,00</td>
                  <td>R$ 0,00</td>
                </tr>

                <tr>
                  <td class="row-desc font-weight-bold row-margin">
                    Receita Líquida
                  </td>
                  <td>R$ 20.000,00</td>
                  <td>R$ 20.000,00</td>
                  <td>R$ 20.000,00</td>
                  <td>R$ 60.000,00</td>
                </tr>

                <tr>
                  <td class="row-desc">Presunção CSLL</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 30.000,00</td>
                </tr>

                <tr>
                  <td class="row-desc">Serviços (32%)</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 30.000,00</td>
                </tr>

                <tr>
                  <td class="row-desc">Comércio (12%)</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 30.000,00</td>
                </tr>

                <tr class="row-margin">
                  <td class="row-desc">Base de cálculo CSLL</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 10.000,00</td>
                  <td>R$ 30.000,00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section class="border-dashed mt-4">
      <div class="row mt-3">
        <div class="offset-6 mt-2 col-6">
          <div class="d-flex justify-content-between mb-2">
            <small class="txt-gray-100 letter-spacing">SUBTOTAL</small>
            <small>R$ 13.200,00</small>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <small class="txt-gray-100 letter-spacing">ALÍQUOTA</small>
            <small>9%</small>
          </div>
          <div class="d-flex justify-content-between">
            <small class="txt-gray-100 letter-spacing">TOTAL</small>
            <span class="font-weight-bold">R$ 1.188,00</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
