import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoCompanyGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.afAuth.currentUser.then((user) => {
        if (user) {
          return user.getIdTokenResult(false).then((tokenResult) => {
            if (tokenResult.claims.company) {
              return true;
            } else {
              this.router.navigate(['/cadastro/empresa']);
              return false
            }
          }).catch((err) => {
            this.router.navigate(['/cadastro/empresa']);
            return false;
          });
        } else {
          return new Promise((resolve) => {
            setTimeout(() => {
              this.afAuth.currentUser.then((user) => {
                if (user) {
                  return user.getIdTokenResult(false).then((tokenResult) => {
                    if (tokenResult.claims.company) {
                      return resolve(true);
                    } else {
                      this.router.navigate(['/cadastro/empresa']);
                      return resolve(false)
                    }
                  }).catch((err) => {
                    this.router.navigate(['/cadastro/empresa']);
                    return resolve(false);
                  });
                } else {
                  this.router.navigate(['/auth/entrar']);
                  return resolve(false);
                }
              });
            }, 2000)
          });
        }
      });
  }
  
}
