import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.afAuth.currentUser.then((u) => {
        if (u) {
          return true;
        } else {
          return new Promise((resolve) => {
            setTimeout(() => {
              this.afAuth.currentUser.then((user) => {
                if (user) {
                  return resolve(true);
                } else {
                  this.router.navigate(['/auth/entrar']);
                  return resolve(false);
                }
              });
            }, 2000)
          });
        }
      });
  }
  
}
