<div ngbDropdown class="d-inline-block">
    <div class="btn-filter" id="categoryDropdown" ngbDropdownToggle>
      <img src="../../assets/icons/ic_thunder.svg" height="20" alt="" />
      <div class="d-flex flex-column mx-3">
        <h6 class="font-weight-bold mb-1">
          {{ (category | titlecase) || "Tipo" }}
        </h6>
      </div>
      <img
        src="../../assets/icons/ic_chevron_down.svg"
        height="20"
        alt=""
      />
    </div>

    <div ngbDropdownMenu aria-labelledby="categoryDropdown">
      <button
        ngbDropdownItem
        routerLink="./"
        [queryParams]="paramsFor(null)"
      >
        Todas
      </button>
      <button
        ngbDropdownItem
        routerLink="./"
        [queryParams]="paramsFor('saída')"
      >
        Emitidas
      </button>
      <button
        ngbDropdownItem
        routerLink="./"
        [queryParams]="paramsFor('entrada')"
      >
        Recebidas
      </button>
    </div>
  </div>