import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmailVerifiedGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.afAuth.currentUser.then((user) => {
        if (user /*&& this.afAuth.currentUser.emailVerified*/) {
          return true;
        } else {
          return new Promise((resolve) => {
            setTimeout(() => {
              this.afAuth.currentUser.then((user) => {
                if (user) {
                  return resolve(true);
                } else {
                  this.router.navigate(['/auth/confirmarEmail']);
                  return resolve(false);
                }
              });
            },2000);
          });
        }
      });
  }
  
}
