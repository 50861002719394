import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss']
})
export class PeriodComponent implements OnInit {
  radioPeriod = 'month';
  radioYear = parseInt(moment().format('YYYY'));
  currentPeriod = moment().format('MM-YYYY');
  queryParams: any = {};
  paramsSubscription: Subscription;
  @Input('initialValue') initialValue;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.currentPeriod = params.get('period') || this.initialValue || moment().format('MM-YYYY');
      if (this.currentPeriod && this.currentPeriod.match(/^\d{2}-\d{4}$/)) {
        this.radioPeriod = 'month';
      } else if (this.currentPeriod && this.currentPeriod.match(/^\dT-\d{4}$/)) {
        this.radioPeriod = 'quarter';
      } else {
        this.radioPeriod = 'month';
      }
      this.onChange.emit(this.currentPeriod);
      this.queryParams = params.keys.reduce((acc, key) => Object.assign(acc, {[key]: params.get(key)}), {});
    });
  }
  
  @Output() onChange = new EventEmitter();

  months() {
    return Array(12)
      .fill(0)
      .map((v, index) => {
        if (index < 9) {
          return `${this.radioYear}-0${index + 1}-01`;
        } else {
          return `${this.radioYear}-${index + 1}-01`;
        }
      });
  }

  quarters() {
    return Array(4)
      .fill(0)
      .map((v, index) => {
        if ((index + 1) * 3 < 10) {
          return `${this.radioYear}-0${(index + 1) * 3}-01`;
        } else {
          return `${this.radioYear}-${(index + 1) * 3}-01`;
        }
      });
  }

  formatDate(date, format = null) {
    if (!format && date && date.match(/^\d{2}-\d{4}$/)) {
      return moment(date, 'MM-YYYY').format('MMM/YYYY');
    } else if (!format && date && date.match(/^\dT-\d{4}$/)) {
      return moment(date, 'QT-YYYY').format('Qtri/YYYY');
    } else {
      return moment(date).format(format);
    }
  }

  isMonthSelected(month) {
    return this.currentPeriod === moment(month).format('MM-YYYY');
  }

  isQuarterSelected(quarter) {
    return this.currentPeriod === moment(quarter).format('QT-YYYY');
  }

  decrementYear() {
    this.radioYear -= 1;
  }

  incrementYear() {
    this.radioYear += 1;
  }

  paramsFor(period?: string) {
    if (period) {
      return Object.assign({}, this.queryParams, {period: period});
    } else {
      let params = Object.assign({}, this.queryParams);
      delete params.period;
      return params;
    }
  }
}
