
<div class="modal-header">
    <div class="d-flex justify-content-between align-items-start w-100">
      <div class="d-flex flex-column">
        <h2 class="font-weight-bold">Trocar Empresa</h2>
        <span class="text-muted txt-gray-200">Escolha a empresa para visualizar. A lista abaixo são das empresas que você tem acesso.</span>
      </div>
      <button
        type="button"
        class="close pull-right txt-gray-300"
        aria-label="Close"
        (click)="modalRef.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
</div>
<div class="modal-body">
    <div *ngFor="let company of companies"
        class="p-2 d-flex align-items-center rounded option"
        [class.border]="company.cnpj == selected"
        [class.border-success]="company.cnpj == selected"
        (click)="changeCompany(company)">
        <div class="flex-grow-1">
            <strong>{{ company.name }}</strong><br>
            <small>{{ company.cnpj | mask: "00.000.000/0000-00"}}</small>
        </div>
        <div *ngIf="company.cnpj == selected">
            <fa-icon [icon]="check" class="text-success"></fa-icon>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-action save-btn" (click)="modalRef.dismiss()">
        <span>CANCELAR</span>
    </button>
</div>