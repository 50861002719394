import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {
  companies = [];
  selected;
  check = faCheck;

  constructor(public modalRef: NgbActiveModal, private afAuth: AngularFireAuth,
    private firestore: AngularFirestore, private functions: AngularFireFunctions,
    private sys: SystemService, private router: Router) { }

  ngOnInit(): void {
    this.afAuth.currentUser.then((user) => {
      this.firestore.doc(`users/${user.uid}`).get().toPromise().then(async (docSnap) => {
        if (docSnap.exists) {
          const data = docSnap.data();
          console.log(data);
          this.companies = await Promise.all((Object.keys(data['roles']) || []).map(async (cnpj) => {
            const snap = await this.firestore.doc(`empresas/${cnpj}`).get().toPromise();
            console.log(snap.data());
            return snap.data();
          }));
        }
      });
      user.getIdTokenResult().then((tokenResult) => {
        this.selected = tokenResult.claims.company;
      });
    })
  }

  changeCompany(company) {
    this.sys.openLoading('Alterando empresa...');
    this.functions.httpsCallable('changeUserCompany')(company).toPromise().then((result) => {
      if (result.status === 200) {
        this.afAuth.currentUser.then((user) => user.getIdTokenResult(true));
        this.modalRef.close();
      } else {
        this.sys.showToast('Não foi possível alterar a empresa');
      }
    })
    .catch((err) => this.sys.showToast('Não foi possível alterar a empresa'))
    .finally(() => this.sys.closeLoading());
  }
}
