import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tax-invoice-type',
  templateUrl: './tax-invoice-type.component.html',
  styleUrls: ['./tax-invoice-type.component.scss']
})
export class TaxInvoiceTypeComponent implements OnInit, OnDestroy {
  type: string;
  queryParams: any = {};
  paramsSubscription: Subscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.paramsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.type = params.get('type');
      this.queryParams = params.keys.reduce((acc, key) => Object.assign(acc, {[key]: params.get(key)}), {});
    });
  }

  ngOnDestroy() {
    this.paramsSubscription.unsubscribe();
  }

  paramsFor(type?: string) {
    if (type) {
      return Object.assign({}, this.queryParams, {type: type});
    } else {
      let params = Object.assign({}, this.queryParams);
      delete params.type;
      return params;
    }
  }
}
