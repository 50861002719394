<div ngbDropdown class="d-inline-block">
    <div class="btn-filter" id="dateDropdown" ngbDropdownToggle>
      <img src="../../assets/icons/ic_calendar.svg" height="20" alt="" />
      <div class="d-flex flex-column mx-3">
        <h6 class="font-weight-bold mb-1">
          {{ currentPeriod ? formatDate(currentPeriod) : 'Período' }}
        </h6>
      </div>
      <img
        src="../../assets/icons/ic_chevron_down.svg"
        height="20"
        alt=""
      />
    </div>
    <div ngbDropdownMenu aria-labelledby="dateDropdown">
      <div class="d-flex justify-content-center">
        <div
          class="btn-group btn-group-sm btn-group-toggle"
          ngbRadioGroup
          name="radioBasic"
          [(ngModel)]="radioPeriod"
        >
          <label ngbButtonLabel class="btn-outline-primary btn-sm">
            <input ngbButton type="radio" value="month" /> Mês
          </label>
          <label ngbButtonLabel class="btn-outline-primary btn-sm">
            <input ngbButton type="radio" value="quarter" /> Trimestre
          </label>
        </div>
      </div>

      <div class="d-flex align-items-center">
        <button class="btn btn-link" (click)="decrementYear()">
          <i class="pi pi-chevron-left"></i>
        </button>
        <span class="flex-grow-1 text-center">{{ radioYear }}</span>
        <button class="btn btn-link" (click)="incrementYear()">
          <i class="pi pi-chevron-right"></i>
        </button>
      </div>

      <div class="px-3 py-2" *ngIf="radioPeriod === 'month'">
        <div class="row">
          <button
            class="btn btn-sm col-4"
            [class.btn-primary]="isMonthSelected(month)"
            *ngFor="let month of months()"
            routerLink="./"
            [queryParams]="paramsFor(formatDate(month, 'MM-YYYY'))"
          >
            <small>{{ formatDate(month, "MMM") }}</small>
          </button>
        </div>
      </div>

      <div class="px-3 py-2" *ngIf="radioPeriod === 'quarter'">
        <div class="row">
          <button
            class="btn btn-sm col-6"
            [class.btn-primary]="isQuarterSelected(quarter)"
            *ngFor="let quarter of quarters()"
            routerLink="./"
            [queryParams]="paramsFor(formatDate(quarter, 'QT-YYYY'))"
          >
            <small>{{ formatDate(quarter, "Qtri/YYYY") }}</small>
          </button>
        </div>
      </div>
    </div>
  </div>