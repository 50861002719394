<div ngbDropdown class="d-inline-block">
    <div class="btn-filter" id="documentDropdown" ngbDropdownToggle>
      <img src="../../assets/icons/ic_document.svg" height="20" alt="" />
      <div class="d-flex flex-column mx-3">
        <h6 class="font-weight-bold mb-1">
          {{ (type | uppercase) || "Documento" }}
        </h6>
      </div>
      <img
        src="../../assets/icons/ic_chevron_down.svg"
        height="20"
        alt=""
      />
    </div>
    <div ngbDropdownMenu aria-labelledby="documentDropdown">
      <button
        ngbDropdownItem
        routerLink="./"
        [queryParams]="paramsFor(null)"
      >
        Todos
      </button>
      <button
        ngbDropdownItem
        routerLink="./"
        [queryParams]="paramsFor('nfe')"
      >
        NFe (Produtos)
      </button>
      <button
        ngbDropdownItem
        routerLink="./"
        [queryParams]="paramsFor('nfse')"
      >
        NFSe (Servicos)
      </button>
      <button ngbDropdownItem>Canceladas</button>
    </div>
  </div>