import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import * as moment from 'moment';
import * as numeral from 'numeral';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { ReportsService } from '../reports.service';
import { CompanyService } from '../company/company.service';

export interface Query {
  title: string;
  desc: string;
  date: string;
  time: string;
  checkStatus: boolean;
  attachmentStatus: 'success' | 'error' | 'pending';
  statusLabel: string;
}

export interface Bill {
  title: string;
  date: string;
  desc: string;
  iconPath: string;
  amount: number;
  tagClass: 'tag-success' | 'tag-blue' | 'tag-purple' | 'tag-danger';
  tagText: string;
}

const QUERIES: Query[] = [
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Ficha CNPJ',
    desc: 'Receita Federal',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'ativo',
    attachmentStatus: 'success',
  },
  {
    title: 'Regime Tributário',
    desc: 'feito em 04/06/20',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'SIMPLES Nacional',
    attachmentStatus: 'success',
  },
  {
    title: 'Sintegra',
    desc: 'Estado',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'não habilitado',
    attachmentStatus: 'error',
  },
  {
    title: 'Certidão Negativa',
    desc: 'Receita Federal e PGFN',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: true,
    statusLabel: 'consultada',
    attachmentStatus: 'success',
  },
  {
    title: 'Certidão Negativa',
    desc: 'Estado / GO',
    date: moment().format('DD/MM/YYYY'),
    time: moment().format('h:mm:ss A'),
    checkStatus: false,
    statusLabel: 'pendente',
    attachmentStatus: 'pending',
  },
];

const BILLS: Bill[] = [
  {
    title: 'Guia - Simples Nacional',
    date: moment().format('LL'),
    desc: 'Impostos (11%) | R$ 14.000',
    iconPath: '../../assets/icons/ic_sign.svg',
    amount: 14000,
    tagClass: 'tag-danger',
    tagText: 'em atraso',
  },
  {
    title: 'Taxa - Funcionamento',
    date: moment().format('LL'),
    desc: 'Ano 2021 | Prefeitura de Goiânia',
    iconPath: '../../assets/icons/ic_flag.svg',
    amount: 250,
    tagClass: 'tag-success',
    tagText: 'pago',
  },
  {
    title: 'Guia - Simples Nacional',
    date: moment().format('LL'),
    desc: 'Impostos (11%) | R$ 14.000',
    iconPath: '../../assets/icons/ic_sign.svg',
    amount: 14000,
    tagClass: 'tag-danger',
    tagText: 'em atraso',
  },
  {
    title: 'Taxa - Funcionamento',
    date: moment().format('LL'),
    desc: 'Ano 2021 | Prefeitura de Goiânia',
    iconPath: '../../assets/icons/ic_flag.svg',
    amount: 250,
    tagClass: 'tag-success',
    tagText: 'pago',
  },
  {
    title: 'Guia - Simples Nacional',
    date: moment().format('LL'),
    desc: 'Impostos (11%) | R$ 14.000',
    iconPath: '../../assets/icons/ic_sign.svg',
    amount: 14000,
    tagClass: 'tag-blue',
    tagText: 'lorem',
  },
  {
    title: 'Taxa - Funcionamento',
    date: moment().format('LL'),
    desc: 'Ano 2021 | Prefeitura de Goiânia',
    iconPath: '../../assets/icons/ic_flag.svg',
    amount: 250,
    tagClass: 'tag-purple',
    tagText: 'lorem',
  },
  {
    title: 'Guia - Simples Nacional',
    date: moment().format('LL'),
    desc: 'Impostos (11%) | R$ 14.000',
    iconPath: '../../assets/icons/ic_sign.svg',
    amount: 14000,
    tagClass: 'tag-danger',
    tagText: 'em atraso',
  },
  {
    title: 'Taxa - Funcionamento',
    date: moment().format('LL'),
    desc: 'Ano 2021 | Prefeitura de Goiânia',
    iconPath: '../../assets/icons/ic_flag.svg',
    amount: 250,
    tagClass: 'tag-success',
    tagText: 'pago',
  },
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  faIcon = faEdit;

  invoices$: Observable<any>;
  filter$: Observable<any>;
  report: any;
  total: number = 0;
  taxInvoicesCount: number = 0;
  taxes = {
    pis: 0,
    cofins: 0,
    irpj: 0,
    csll: 0,
    iss: 0,
    icms: 0,
    cpp: 0,
  };
  totalTaxes = 0;
  contactsCount = 0;

  invoicesSubscription?: Subscription;
  taxCalcSubscription?: Subscription;

  fakeNfSummaryStats = [40, 80, 23];
  fakeNfSummaryTotal = this.fakeNfSummaryStats.reduce((a, b) => a + b, 0);

  nfSummaryActiveFiler: number = 2;
  public nfSummaryChartLabels: Label[] = [
    'Emitidas',
    'Recebidas',
    'Canceladas',
  ];
  public nfSummaryChartData: MultiDataSet = [[...this.fakeNfSummaryStats]];
  public nfSummaryChartType: ChartType = 'doughnut';
  public nfSummaryChartColors: Color[] = [
    { backgroundColor: ['#2BC155', '#FF6D4C', '#3E4954'] },
  ];
  public nfSummaryChartOptions: ChartOptions = {
    legend: {
      display: false,
    },
  };

  selectedRevenueFilter: string = 'Mês';
  selectedTaxesFilter: string = 'Mensal';
  selectedBillsFilter: string = 'Mensal';

  @ViewChild('revenueChartCanvas') revenueChartCanvas: any;

  public lineChartData: ChartDataSets[] = [
    {
      data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 65, 59],
      label: 'Receita',
      borderWidth: 5,
      borderJoinStyle: 'round',
      pointRadius: 0,
    },
    {
      data: [35, 50, 44, 61, 56, 57, 58, 65, 77, 80, 65, 47],
      label: 'Impostos',
      borderWidth: 5,
      borderJoinStyle: 'round',
      pointRadius: 0,
    },
  ];
  public lineChartLabels: Label[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  public lineChartOptions: ChartOptions & { annotation?: any } = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    elements: {
      point: {
        radius: 0
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontFamily: 'Poppins',
            maxTicksLimit: 10
          },
          gridLines: {
            display: false
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontFamily: 'Poppins',
            stepSize: 20,
            beginAtZero: true,
            maxTicksLimit: 6,
            padding: 8
          },
          gridLines: {
            drawBorder: false
          }
        },
      ],
    },
    plugins: {
      decimation: {
        enabled: true,
        algorithm: 'lttb',
        samples: 10
      }
    }
  };
  public lineChartColors: Color[] = [];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';

  queries: Query[] = QUERIES;
  bills: Bill[] = BILLS;

  reportSubs: Subscription;

  constructor(
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private reportSrv: ReportsService,
    private compSrv: CompanyService
  ) {
    this.filter$ = of({
      type: null,
      period: moment().format('MM-YYYY'),
      document: null,
    });
    this.invoices$ = of([]);
  }

  percent(current, prior) {
    if (prior > 0) {
      return Math.abs((current - prior) / prior);
    } else {
      return 0;
    }
  }

  isPositive(current, prior) {
    return current > prior
  }

  ngAfterViewInit() {
    this.initRevenueChart();
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.reportSubs = combineLatest([this.route.queryParamMap, this.compSrv.company$]).subscribe(([params, company]) => {
      this.getReport({period: params.get('period') || moment().format('MM-YYYY')});
    })
    this.filter$ = this.route.queryParamMap.pipe(
      map((params) => {
        const filter = {
          type: params.get('type'),
          period: params.get('period') || moment().format('MM-YYYY'),
          category: params.get('document'),
        };
        return filter;
      })
    );
    const cnpj$ = this.compSrv.company$.pipe(map(company => company.cnpj))

    this.invoices$ = combineLatest([this.filter$, cnpj$]).pipe(
      switchMap(([filter, cnpj]) => {
        if (cnpj) {
          const formattedPeriod = filter.period || moment().format('MM-YYYY');
          return this.afStore
            .collection(
              `empresas/${cnpj}/apuracoes/${formattedPeriod}/notasFiscais`,
              (ref) => {
                let query = ref.where('cnpj', '==', cnpj);
                if (filter.type) {
                  query = query.where('category', '==', filter.type);
                }
                if (filter.document) {
                  query = query.where('type', '==', filter.document);
                }
                return query;
              }
            )
            .valueChanges();
        } else {
          return of([]);
        }
      })
    );

    this.taxCalcSubscription = combineLatest([this.filter$, cnpj$])
      .pipe(
        take(1),
        switchMap(([filter, cnpj]) => {
          if (cnpj) {
            const formattedPeriod = filter.period || moment().format('MM-YYYY');
            return this.afStore
              .doc(`empresas/${cnpj}/apuracoes/${formattedPeriod}`)
              .valueChanges();
          } else {
            return of(null);
          }
        })
      )
      .subscribe((taxDoc: any) => {
        if (taxDoc) {
          this.taxes = {
            pis: taxDoc.impostosCalculados?.PIS || 0,
            cofins: taxDoc.impostosCalculados?.COFINS || 0,
            irpj: taxDoc.impostosCalculados?.IRPJ || 0,
            csll: taxDoc.impostosCalculados?.CSLL || 0,
            iss: taxDoc.impostosCalculados?.ISS || 0,
            icms: taxDoc.impostosCalculados?.ICMS || 0,
            cpp: taxDoc.impostosCalculados?.CPP || 0,
          };
          this.totalTaxes = Object.values(this.taxes).reduce(
            (acc, v) => acc + v,
            0
          );
          this.total = taxDoc.total;
          this.contactsCount = taxDoc.contactsCount;
        }
      });

    this.invoicesSubscription = this.invoices$.subscribe((invoices) => {
      //this.total = invoices.reduce((acc: number, invoice: any) => acc + parseFloat(invoice.total), 0);
      this.taxInvoicesCount = invoices.length;
    });
  }

  ngOnDestroy() {
    this.invoicesSubscription?.unsubscribe();
    this.taxCalcSubscription?.unsubscribe();
    this.reportSubs?.unsubscribe();
  }

  getReport(params = {}) {
    return this.reportSrv.getReport(params).then((report) => {
      console.log(report);
      this.report = report;
      this.nfSummaryChartData = [[report.current.taxInvoicesCount.emitted, report.current.taxInvoicesCount.received, report.current.taxInvoicesCount.cancelled]];
      this.lineChartLabels = Object.keys(report.current.taxes).map(d => moment(d).format('DD/MMM'));

      this.lineChartData[0].data = Object.values(report.current.taxes).map((d: any) => d.total);
      this.lineChartData[1].data = Object.values(report.current.taxes).map((d: any) => d.taxes);
    })
    .catch((e) => console.log(e))
  }

  formatNumber(num: number, format = '0a') {
    // console.log(num);
    return numeral(num).format(format);
  }

  setRevenueFilter(filter) {
    this.selectedRevenueFilter = filter;
  }
  setTaxesFilter(filter) {
    this.selectedTaxesFilter = filter;
  }
  setBillsFilter(filter) {
    this.selectedBillsFilter = filter;
  }

  initRevenueChart() {
    let gradientRevenue = this.revenueChartCanvas.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 250);
    gradientRevenue.addColorStop(0, 'rgba(47, 76, 221, 0.3)');
    gradientRevenue.addColorStop(1, 'rgba(47, 76, 221, 0)');

    let gradientTaxes = this.revenueChartCanvas.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 250);
    gradientTaxes.addColorStop(0, 'rgba(181, 25, 236, 0.3)');
    gradientTaxes.addColorStop(1, 'rgba(181, 25, 236, 0)');

    this.lineChartColors = [
      {
        borderColor: 'rgba(47, 76, 221, 1)',
        backgroundColor: gradientRevenue,
        borderWidth: 3,
        pointBorderColor: 'rgba(47, 76, 221, .5)',
        pointBackgroundColor: 'rgba(47, 76, 221, .5)',
      },
      {
        borderColor: 'rgba(181, 25, 236, 1)',
        backgroundColor: gradientTaxes,
        borderWidth: 3,
        pointBorderColor: 'rgba(181, 25, 236, .5)',
        pointBackgroundColor: 'rgba(181, 25, 236, .5)',
      },
    ];
  }
}
